// import { CodeIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";
import Reveal, { SlideContent } from "./Components";
import { RevealImage } from "./Components";

export default function Projects() {
  let delayGrow = 0;
  let delayShrink = 0;
  useEffect(() => {
    const adjustMargin = () => {
      const items = document.querySelectorAll(".item"); // Select all items
      const screenWidth = window.innerWidth;

      // Check for large screen size (Bootstrap lg breakpoint)
      if (screenWidth >= 992) {
        // Apply negative offset to the last images of the first two rows
        items.forEach((item, index) => {
          // Apply margin-top only to the last images of each of the first two rows
          if (index === 3 || index === 5) {
            // This applies to every third item (2, 5, 8, ...)
            item.classList.add("negative-offset-10"); // Add negative offset class
          } else if (index === 6 || index === 8) {
            // This applies to every third item (2, 5, 8, ...)
            item.classList.add("negative-offset-30"); // Add negative offset class
          } else if (index === 11) {
            // This applies to every third item (2, 5, 8, ...)
            item.classList.add("negative-offset-30"); // Add negative offset class
          } else {
            item.classList.remove("negative-offset-10"); // Remove offset for other items
            item.classList.remove("negative-offset-30"); // Remove offset for other items
          }

          // if (
          //   (index % 3 === 0 && index !== 0) ||
          //   (index % 3 == 2 && index !== 0 && index !== 2)
          // ) {
          //   console.log("negative");
          //   // This applies to every third item (2, 5, 8, ...)
          //   item.classList.add("negative-offset"); // Add negative offset class
          // } else {
          //   item.classList.remove("negative-offset"); // Remove offset for other items
          // }
        });
      } else {
        // Reset margins for smaller screens
        items.forEach((item) => {
          item.classList.remove("negative-offset-20");
        });
      }
    };

    // Initial call
    adjustMargin();

    // Call on window resize
    window.addEventListener("resize", adjustMargin);

    // Cleanup
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);
  return (
    <div className="unslate_co--section" id="portfolio-section">
      <div className="container">
        <div className="relative">
          <div className="loader-portfolio-wrap">
            <div className="loader-portfolio"></div>
          </div>{" "}
        </div>
        <div id="portfolio-single-holder"></div>

        <div className="portfolio-wrapper">
          <div className="d-flex align-items-center mb-4 ">
            <h2 className="mr-auto heading-h2">
              <SlideContent>
                <span>Portfolio</span>
              </SlideContent>
            </h2>
          </div>
          <div id="posts" className="row gutter-isotope-item">
            <div
              className="item web branding col-sm-6 col-md-6 col-lg-4 isotope-mb-2 "
              style={{
                height: "50%",
                marginTop: "6.5%",
              }}
            >
              <RevealImage>
                <a
                  href="portfolio-single-1.html"
                  className="portfolio-item ajax-load-page isotope-item gsap-reveal-img"
                  data-id="1"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-link2"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Gant Chart</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/gant.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div className="item branding packaging illustration col-sm-6 col-md-6 col-lg-4 isotope-mb-2 ">
              <RevealImage
                delayGrow={(delayGrow += 0.5)}
                delayShrink={(delayShrink += 0.5)}
              >
                <div>
                  <a
                    href="portfolio-single-3.html"
                    className="portfolio-item"
                    data-id="3"
                  >
                    <div className="overlay">
                      <span className="wrap-icon icon-link2"></span>
                      <div className="portfolio-item-content">
                        <h3>Reworking</h3>
                        <p>branding, packaging, illustration</p>
                      </div>
                    </div>
                    <img
                      src={require("../images/work_2_md.jpg")}
                      className="lazyload  img-fluid"
                      alt="Images"
                    />
                  </a>
                </div>
              </RevealImage>
            </div>

            <div
              className="item branding packaging col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{ height: "50%", marginTop: "6.5%" }}
            >
              <RevealImage
                delayGrow={(delayGrow += 0.5)}
                delayShrink={(delayShrink += 0.5)}
              >
                <a
                  href="portfolio-single-4.html"
                  className="portfolio-item isotope-item gsap-reveal-img ajax-load-page"
                  data-id="4"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-link2"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Gant Task Addition</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/gant-edit.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div
              className="item web packaging col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{
                height: "50%",
              }}
            >
              <RevealImage
                delayGrow={(delayGrow += 0.5)}
                delayShrink={(delayShrink = 0.5)}
              >
                <a
                  href="images/work_4_full.jpg"
                  className="portfolio-item isotope-item gsap-reveal-img"
                  data-fancybox="gallery"
                  data-caption="Watch"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-photo2"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Kanban</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/kanban.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div className="item illustration packaging col-sm-6 col-md-6 col-lg-4 isotope-mb-2">
              <RevealImage
                delayGrow={(delayGrow += 1)}
                delayShrink={(delayShrink = 0.5)}
              >
                <a
                  href="images/work_5_md.jpg"
                  className="portfolio-item isotope-item gsap-reveal-img"
                  data-fancybox="gallery"
                  data-caption="Shoe Rebranding"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-photo2"></span>
                    <div className="portfolio-item-content">
                      <h3>Sensor Monitoring App</h3>
                      <p>BLE based communication</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/bluetoothapp.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div
              className="item web branding col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{
                height: "50%",
              }}
            >
              <RevealImage
                delayGrow={(delayGrow -= 0.5)}
                delayShrink={delayShrink}
              >
                <a
                  href="portfolio-single-2.html"
                  className="portfolio-item ajax-load-page item-portrait isotope-item gsap-reveal-img"
                  data-id="2"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-link2"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Kanban Task Addition</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/kanban-add.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div
              className="item branding packaging col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{ height: "60%" }}
            >
              <RevealImage
                delayGrow={(delayGrow += 1)}
                delayShrink={delayShrink}
              >
                <a
                  href="images/work_7_a_md.jpg"
                  className="portfolio-item item-portrait isotope-item gsap-reveal-img "
                  data-fancybox="gallery"
                  data-caption="Modern Building"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-photo2"></span>
                    <div className="portfolio-item-content">
                      <h3>Home Automation App</h3>
                      <p>Wifi based Automation app</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/wifiapp.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div className="item web branding col-sm-6 col-md-6 col-lg-4 isotope-mb-2">
              <RevealImage
                delayGrow={delayGrow + 0.5}
                delayShrink={delayShrink}
              >
                <a
                  href="https://www.youtube.com/watch?v=mwtbEGNABWU"
                  className="portfolio-item isotope-item gsap-reveal-img"
                  data-fancybox="gallery"
                  data-caption="Showreel 2019"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-play_circle_filled"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Collapsed View</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/kanban.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>

            <div
              className="item web illustration col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{
                height: "50%",
              }}
            >
              <RevealImage delayGrow={delayGrow} delayShrink={delayShrink}>
                <a
                  href="images/work_9_a_md.jpg"
                  className="portfolio-item isotope-item gsap-reveal-img"
                  data-fancybox="gallery"
                  data-caption="Render Packaging"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-photo2"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Gant chart collapsed view</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/gant-collapse.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>
            <div
              className="item web illustration col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{
                height: "50%",
              }}
            ></div>
            <div
              className="item web illustration col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{
                height: "50%",
              }}
            ></div>
            <div
              className="item web illustration col-sm-6 col-md-6 col-lg-4 isotope-mb-2"
              style={{
                height: "50%",
              }}
            >
              <RevealImage
                delayGrow={delayGrow + 1.5}
                delayShrink={delayShrink}
              >
                <a
                  href="images/work_9_a_md.jpg"
                  className="portfolio-item isotope-item gsap-reveal-img"
                  data-fancybox="gallery"
                  data-caption="Render Packaging"
                >
                  <div className="overlay">
                    <span className="wrap-icon icon-photo2"></span>
                    <div className="portfolio-item-content">
                      <h3>Task Scheduler</h3>
                      <p>Team View</p>
                    </div>
                  </div>
                  <img
                    src={require("../images/scheduler-team.png")}
                    className="lazyload  img-fluid"
                    alt="Images"
                  />
                </a>
              </RevealImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
