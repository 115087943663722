const google_logo = require("./images/logo-google.png");
const ProjectName = "Abdul Raqeeb";
const SupportEmail = "contact.abdulraqeeb.dev@gmail.com";
const SupportPhone = "+92 311 1492280";
const EmailJS_ServiceId = "service_pzzxr9z";
const EmailJS_TemplateId = "template_yz912lr";
const EmailJS_PublicKey = "9oz9aSFS8J5Bgyyvk";
const EmailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
module.exports = {
  google_logo,
  ProjectName,
  SupportEmail,
  EmailRegex,
  SupportPhone,
  EmailJS_ServiceId,
  EmailJS_TemplateId,
  EmailJS_PublicKey,
};
